<template>
  <div class="header-bar" :class="border ? 'border' : ''">
    <Icon
      name="arrow-left"
      color="#333"
      size="18"
      class="back-icon left-icon"
      @click="back"
    />

    {{ title }}

    <div class="right-icon">
      <span @click="detail" v-if="rightText">
        {{ rightText }}
      </span>
      <Icon
        @click="detail"
        :name="rightIcon"
        v-if="rightIcon"
        color="#333"
        size="20"
      ></Icon>
    </div>
  </div>
</template>
<script>
import { Popup, Icon, Button } from 'vant'
export default {
  name: 'pageHeader',
  components: {
    Popup,
    Icon,
    Button
  },
  data() {
    return {
      showPopup: false,
      loading: false
    }
  },
  props: {
    title: String,
    rightText: String,
    rightIcon: String,
    border: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  mounted() {},
  methods: {
    showRefresh() {
      if (!this.moreIcon) {
        return
      }
      this.showPopup = true
    },
    back() {
      this.$emit('back')
    },
    detail() {
      this.$emit('detail')
    }
  }
}
</script>