<template>
  <div class="chat-page" :class="`${pageType} ${chatType}`">
    <div class="modal-title flex-center-between" v-if="type === 'modal'">
      <div class="flex-center">
        <i class="el-icon-service"></i>
        {{ $t('zai-xian-ke-fu') }}
      </div>
      <div @click="hideModal" class="action">
        <i class="el-icon-caret-bottom"></i>
      </div>
    </div>
    <headerBar v-else :title="title" @back="back"></headerBar>
    <div class="message-box">
      <div
        class="list"
        v-for="(item, i) in messageArr"
        :key="i"
        :class="item.SenderID == userId ? 'flex-start-end right' : 'flex-start'"
      >
        <div class="header" v-if="item.SenderID != userId">
          <img :src="kefuUrl" alt="" />
        </div>
        <div class="content-outer">
          <div class="content">
            {{ item.isTranslate ? item.translateMsg : item.Content }}
          </div>
        </div>
        <div class="header" v-if="item.SenderID == userId">
          <img :src="logoUrl" alt="" />
        </div>
      </div>
    </div>

    <div class="bottom-btn flex-center-between">
      <div class="input flex-1">
        <input type="text" v-model="content" :placeholder="$t('qing-shu-ru')" />
      </div>
      <div class="chat-btn">
        <Button @click="sendMsg">{{ $t('fa-song-0') }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Icon } from 'vant'
import headerBar from '@/components/header'
import { initWebSocket } from '@/utils/common'
import { getUserId, getToken } from '@/utils/auth'
import { messageList, messageRead, translateMsg } from '@/api/shop'
export default {
  components: {
    Button,
    headerBar,
    Icon
  },
  data() {
    return {
      uid: '',
      title: '',
      content: '',
      kefuUrl: require('@/assets/imgs/header/header1.png'),
      logoUrl: require('@/assets/imgs/header/header2.png'),
      messageArr: [],
      client: null,
      webSocket: null,
      page: {
        page: 1
      },
      pageType: ''
    }
  },
  props: {
    type: String
  },
  computed: {
    userId() {
      return getUserId()
    },
    lang() {
      return this.$store.state.lang
    },
    langName() {
      return this.$store.state.langName
    },
    chatType() {
      return this.$store.state.chatType
    }
  },
  beforeDestroy() {
    if (this.webSocket && this.webSocket.close) {
      this.webSocket.close()
    }
  },
  mounted() {
    this.uid = this.$route.query.uid
    this.title = this.$route.query.name
    this.pageType = this.$route.name === 'wapUserChat' ? 'user' : ''
    this.initMessage()
    if (this.userId) {
      this.initSocket()
    }
  },
  methods: {
    initMessage() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', 30)
      form.append('ConversationID', this.uid)
      messageList(form).then((res) => {
        let list = res.data.Items.reverse()
        this.messageArr = list
        setTimeout(() => {
          window.scrollTo({ top: 2000 })
        }, 300)
        this.initRead()
      })
    },
    initRead() {
      let ids = this.messageArr
        .filter((v) => {
          console.log('v.ReceiverID, this.userId')
          console.log(v.ReceiverID, this.userId)
          return v.ReceiverID == this.userId
        })
        .map((v) => {
          return v.ID
        })
      if (ids.length > 0) {
        messageRead({
          MessageID: ids
        })
      }
    },
    async initSocket() {
      //初始化weosocket
      let client = await initWebSocket(this.userId, this.websocketonmessage)
      this.client = client
      this.webSocket = client.ws
    },
    back() {
      this.$router.go(-1)
    },
    toTranslate(data, i) {
      if (data.translateMsg) {
        this.$set(this.messageArr[i], 'isTranslate', true)
        return
      }
      let form = new FormData()
      form.append('ID', data.ID)
      translateMsg(form).then((res) => {
        let msg = res.data.Content
        this.$set(this.messageArr[i], 'isTranslate', true)
        this.$set(this.messageArr[i], 'translateMsg', msg)
      })
    },
    showOriginMsg(i) {
      this.$set(this.messageArr[i], 'isTranslate', false)
    },
    websocketonmessage(e) {
      let redata = {}
      try {
        redata = JSON.parse(e.body)
        this.setListData(redata)
      } catch (e) {}
    },
    setListData(data) {
      this.messageArr.push(data)
      let ids = [data.ID]
      messageRead({
        MessageID: ids
      })
      setTimeout(() => {
        window.scrollTo({ top: 10000 })
      }, 300)
    },
    sendMsg() {
      if (!this.content) {
        this.$toast.fail(this.$t('fa-song-nei-rong-bu-neng-wei-kong'))
        return
      }
      // 发送消息
      let message = {
        ReceiverID: parseInt(this.uid),
        Type: 0,
        Content: this.content,
        token: getToken()
      }
      this.client.send(
        '/exchange/direct_logs/chat_queue',
        { 'content-type': 'application/json' },
        JSON.stringify(message)
      )
      this.content = ''
    },
    hideModal() {
      this.$emit('cancel')
    }
  }
}
</script>