import axios from "axios";
import Vue from "vue";
import { Toast } from 'vant';
import { getToken, removeToken, getUserType } from "./auth";
import router from '../router'
import store from '../store'
Vue.use(Toast);
// 创建axios实例
const service = axios.create({
  baseURL: '', // api的base_url
  timeout: 100000, // 请求超时时间
  headers: { "Cache-Control": "no-cache", Pragma: "no-cache"}
});

// 添加请求拦截器
service.interceptors.request.use(
  request => {
    let token = getToken();
    if (token) {
      request.headers['Token'] = token;
    }
    request.headers.language = localStorage.getItem('locale') || 'en'
    return request
  },
  error => {
    return Promise.reject(error);
  }
);

// 拦截返回的数据res,通过返回值直接获取到服务器的数据
service.interceptors.response.use(
  response => {
    
    const res = response.data;
    return res
  },
  error => {
    if (error.response.status == 401 || error.response.status == 403) {
      removeToken()
      store.state.isLogin = false
      let pageType = store.state.pageType
      let userType = getUserType()
      let name = pageType == 'h5' ? (userType == '1' ? 'wapLogin' : 'wapUserLogin'): (userType == '1' ? 'login' : 'userLogin')

      router.push({
        name: name
      })
    }
    return Promise.reject(error);
  }
);

export default service;
