import Cookies from "js-cookie";

const TokenKey = "WebToken";
const UserKey = "WebUserName";
const UserIdKey = "WebUserId";
const UserTypeKey = "WebUserType";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  Cookies.set(TokenKey, token);
}

export function removeToken() {
  // 清楚sessionStorage
  sessionStorage.clear();
  Cookies.remove(TokenKey);
}

export function getUser() {
  return Cookies.get(UserKey);
}

export function setUser(user) {
  Cookies.set(UserKey, user);
}

export function removeUser() {
  Cookies.remove(UserKey);
}

export function getUserId() {
  return Cookies.get(UserIdKey);
}


export function setUserId(id) {
  Cookies.set(UserIdKey, id);
}
export function removeUserId() {
  Cookies.remove(UserIdKey);
}
export function getUserType() {
  return Cookies.get(UserTypeKey);
}
export function setUserType(type) {
  Cookies.set(UserTypeKey, type);
}

export function removeUserType() {
  Cookies.remove(UserTypeKey);
}
