import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import zhTWLocale from 'element-ui/lib/locale/lang/zh-TW'
import arLocale from 'element-ui/lib/locale/lang/ar'
import esLocale from 'element-ui/lib/locale/lang/es'
import frLocale from 'element-ui/lib/locale/lang/fr'
import idLocale from 'element-ui/lib/locale/lang/id'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import koLocale from 'element-ui/lib/locale/lang/ko'
import ptLocale from 'element-ui/lib/locale/lang/pt'
import ruLocale from 'element-ui/lib/locale/lang/ru-RU'
import thLocale from 'element-ui/lib/locale/lang/th'
import heLocale from 'element-ui/lib/locale/lang/he'
import trLocale from 'element-ui/lib/locale/lang/tr-TR'
import viLocale from 'element-ui/lib/locale/lang/vi'
import deLocale from 'element-ui/lib/locale/lang/de'
import itLocale from 'element-ui/lib/locale/lang/it'
import kkLocale from 'element-ui/lib/locale/lang/kz'
import msLocale from 'element-ui/lib/locale/lang/ms'
import roLocale from 'element-ui/lib/locale/lang/ro'
import hyLocale from 'element-ui/lib/locale/lang/hy-AM'
import bgLocale from 'element-ui/lib/locale/lang/bg'
import plLocale from 'element-ui/lib/locale/lang/pl'
import ltLocale from 'element-ui/lib/locale/lang/lt'

Vue.use(VueI18n)

// 国际化
const i18n = new VueI18n({
  locale: store.state.language || 'en', // set locale
  messages: {
    'zh-CN': {
      ...require('./lang/zh-CN.yaml'),
      ...zhLocale
    }, // 简体中文语言包
    'en': {
      ...require('./lang/en.yaml'),
      ...enLocale
    }, // 英文
    'zh-TW': {
      ...require('./lang/zh-TW.yaml'),
      ...zhTWLocale
    }, // 繁体中文语言包
    'ar': {
      ...require('./lang/ar.yaml'), // 阿拉伯语
      ...arLocale
    },
    'es': {
      ...require('./lang/es.yaml'),
      ...esLocale
    }, // 西班牙语
    'fr': {
      ...require('./lang/fr.yaml'),
      ...frLocale
    }, // 法语
    'id': {
      ...require('./lang/id.yaml'),
      ...idLocale
    }, // 印尼语
    'ja': {
      ...require('./lang/ja.yaml'),
      ...jaLocale
    }, // 日语
    'ko': {
      ...require('./lang/ko.yaml'),
      ...koLocale
    }, // 韩语
    'pt': {
      ...require('./lang/pt.yaml'),
      ...ptLocale
    }, // 葡萄牙语
    'ru': {
      ...require('./lang/ru.yaml'),
      ...ruLocale
    }, // 俄语
    'th': {
      ...require('./lang/th.yaml'),
      ...thLocale
    }, // 泰语
    'he': {
      ...require('./lang/he.yaml'),
      ...heLocale
    }, // 希伯来语
    'tr': {
      ...require('./lang/tr.yaml'),
      ...trLocale
    }, // 土耳其语
    'hi': {
      ...require('./lang/hi.yaml'),
    }, // 印地语
    'vi': {
      ...require('./lang/vi.yaml'),
      ...viLocale
    }, // 越南语
    'de': {
      ...require('./lang/de.yaml'),
      ...deLocale
    }, // 德语
    'it': {
      ...require('./lang/it.yaml'),
      ...itLocale
    }, // 意大利语
    'kk': {
      ...require('./lang/kk.yaml'),
      ...kkLocale
    }, // 哈萨克斯坦语
    'ms': {
      ...require('./lang/ms.yaml'),
      ...msLocale
    }, // 马来语
    'ka': {
      ...require('./lang/ka.yaml'),
    }, // 马来语
    'ro': {
      ...require('./lang/ro.yaml'),
      ...roLocale
    }, // 罗马利亚语
    'uk': {
      ...require('./lang/uk.yaml'),
    }, // 马来语
    'hy': {
      ...require('./lang/hy.yaml'),
      ...hyLocale
    }, // 亚美尼亚语
    'bg': {
      ...require('./lang/bg.yaml'),
      ...bgLocale
    },// 保加利亚语
    'pl': {
      ...require('./lang/pl.yaml'),
      ...plLocale
    },// 波兰语
    'lt': {
      ...require('./lang/lt.yaml'),
      ...ltLocale
    },// 立陶宛语
  },
  silentTranslationWarn: false,
  dateTimeFormats: {

  }
})


export default i18n
