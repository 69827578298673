
import Stomp from 'stompjs';


export function initWebSocket(id, websocketonmessage) {
   let socketUrl = ''
  const env = process.env.NODE_ENV
  if (env == 'development') {
    socketUrl = process.env.VUE_APP_WEBSOCKET_URL 
  } else {
    let host = window.location.host
    let href = window.location.href
    socketUrl = (href.indexOf('https:') == 0 ? 'wss://' : 'ws://') + host + '/ws'
  }

  // socketUrl = 'ws://172.104.108.97:7402/ws';  // 测试地址
  return new Promise((resolve) => {
    // const socket = new SockJS(socketUrl); // 使用 SockJS 创建 WebSocket 连接
    // const client = Stomp.over(socket); // 使用 Stomp.over 代替 Stomp.client
    const client = Stomp.client(socketUrl);


    client.reconnectDelay = 5000;
    client.heartbeatIncoming = 4000;
    client.heartbeatOutgoing = 4000;

    client.connect({
      host: "/mall-ex",
    }, function (frame) {
      // 订阅消息队列
      client.subscribe(`/exchange/direct_logs/uid${id}`, function (message) {
        // 处理收到的消息
        if (websocketonmessage) {
          websocketonmessage(message);
        }
      });
    }, function (error) {
      console.error("Connection error: " + error);
    });

    client.onStompError = function (frame) {
      console.error("Broker reported error: " + frame.headers['message']);
      console.error("Additional details: " + frame.body);
    };

    // 暂存client对象以便后续操作（如断开连接）
    resolve(client);
  });
}

export function getBaseUrl(url) {
  if (url.indexOf('/upload') === 0) {
    return url
  } else {
    return `/upload/${url}`
  }
}
