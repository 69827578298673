import fetch from "../utils/fetch";

// 全局配置
export function configList(data) {
  return fetch({
    url: '/api/user/setting-list',
    method: 'post',
    data: data
  });
}

// WithdrawalStatistics 提现统计
export function WithdrawalStatistics(data) {
    return fetch({
        url: '/api/withdrawal-statistics',
        method: 'post',
        data: data
    });
}
// 用户信息
export function userInfo(data) {
  return fetch({
    url: '/api/user/user-info',
    method: 'post',
    data: data
  });
}

export function customization(data) {
  return fetch({
    url: '/api/customization',
    method: 'post',
    data: data
  });
}
// 退出登录
export function logout(data) {
  return fetch({
    url: '/api/user/logout',
    method: 'delete',
  });
}
// 查询钱包信息
export function getWalletInfo (data) {
  return fetch({
    url: '/api/user/user-account-list',
    method: 'post',
    data
  })
}
// 地址列表
export function shippingAddressList (data) {
  return fetch({
    url: '/api/user/shipping-address-lists',
    method: 'post',
    data
  })
}
// 新增地址列表
export function addShippingAddress (data) {
  return fetch({
    url: '/api/user/shipping-address-add',
    method: 'post',
    data
  })
}
// 设为默认地址
export function setDefaultAddress (data) {
  return fetch({
    url: '/api/user/shipping-address-select',
    method: 'post',
    data
  })
}
// 删除地址
export function deleteAddress (data) {
  return fetch({
    url: '/api/user/shipping-address-delete',
    method: 'post',
    data
  })
}
// 加入购物车
export function addCar (data) {
  return fetch({
    url: '/api/user/shopping-cart-add',
    method: 'post',
    data
  })
}
// 购物车列表
export function carList (data) {
  return fetch({
    url: '/api/user/shopping-cart-list',
    method: 'post',
    data
  })
}
// 购物车删除
export function removeCar (data) {
  return fetch({
    url: '/api/user/shopping-cart-delete',
    method: 'post',
    data
  })
}
// 创建订单
export function addOrder (data) {
  return fetch({
    url: '/api/user/order-add',
    method: 'post',
    data
  })
}
// 订单支付
export function orderPay (data) {
  return fetch({
    url: '/api/user/order-pay',
    method: 'post',
    data
  })
}
// 订单列表
export function getOrderList (data) {
  return fetch({
    url: '/api/user/order-lists',
    method: 'post',
    data
  })
}
// 订单取消
export function cancelOrder (data) {
  return fetch({
    url: '/api/user/order-cancel',
    method: 'post',
    data
  })
}
// 充值记录
export function rechargeLog (data) {
  return fetch({
    url: '/api/user/pay-record-lists',
    method: 'post',
    data
  })
}
// 提现记录
export function withdrawLog (data) {
  return fetch({
    url: '/api/user/withdraw-lists',
    method: 'post',
    data
  })
}
// 修改登录密码
export function editPassword (data) {
  return fetch({
    url: '/api/user/update-password',
    method: 'post',
    data
  })
}
// 修改交易密码
export function editSafePassword (data) {
  return fetch({
    url: '/api/user/update-pay-password',
    method: 'post',
    data
  })
}
// 设置交易密码
export function setSafePassword (data) {
  return fetch({
    url: '/api/user/set-pay-password',
    method: 'post',
    data
  })
}
// 校验交易密码
export function checkSafePassword (data) {
  return fetch({
    url: '/api/user/check-pay-password',
    method: 'post',
    data
  })
}
// 充值地址信息
export function getRechargeInfo () {
  return fetch({
    url: '/api/user/payment-management-lists',
    method: 'post',
    data: {}
  })
}
// 充值提交
export function addRecharge (data) {
  return fetch({
    url: '/api/user/pay-record-add',
    method: 'post',
    data: data
  })
}
// 提现提交
export function addWithdraw (data) {
  return fetch({
    url: '/api/user/withdraw-add',
    method: 'post',
    data: data
  })
}

// getKefuList 获取客服列表
export function getKefuList(data) {
  return fetch({
    url: '/api/user/customer-list',
    method: 'post',
    data: data
  });
}