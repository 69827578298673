import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'globalthis/auto'

import '@vant/touch-emulator';
import FastClick from 'fastclick'


import i18n from './i18n/index'
Vue.prototype.i18n = i18n

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// Vue.use(ElementUI)
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import zhTW from 'vant/es/locale/lang/zh-TW';



import { Locale } from 'vant';
if(localStorage.getItem('locale') == 'en'){
  Locale.use('en-US', enUS);
} else {
  Locale.use('zh-TW', zhTW);
}
let vuethis;


Vue.config.productionTip = false

import './assets/css/common.less';
import './assets/fonts/iconfont.css';
import 'lib-flexible/flexible'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

FastClick.prototype.focus = function(targetElement) {
  var length;
  var deviceIsWindowsPhone = navigator.userAgent.indexOf("Windows Phone") >= 0;
  var deviceIsIOS = /iP(ad|hone|od)/.test(navigator.userAgent) && !deviceIsWindowsPhone;
  //兼容处理:在iOS7中，有一些元素（如date、datetime、month等）在setSelectionRange会出现TypeError
  //这是因为这些元素并没有selectionStart和selectionEnd的整型数字属性，所以一旦引用就会报错，因此排除这些属性才使用setSelectionRange方法
  if (deviceIsIOS && targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month' && targetElement.type !== 'email') {
    length = targetElement.value.length;
    targetElement.setSelectionRange(length, length); //修复bug ios 11.3不弹出键盘，这里加上聚焦代码，让其强制聚焦弹出键盘
    targetElement.focus();
  } else {
    targetElement.focus();
  }
}
vuethis = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
